import React from "react";

export const QuestionDescription = (props) => {
  return (
    <div className="mb-4 px-4 py-2">
      <p
        style={{
          font: "normal normal normal 14px/17px Yu Gothic",
          letterSpacing: "0px",
          color: "#363636",
        }}
      >
        {props.children}
      </p>
    </div>
  );
};
