import React from "react";
import { s3Url } from "../../../configs/common";
import { compareAnswer } from "../../../functions/compareAnswer";
import { JudgementMessage } from "../../JudgmentMessage";
import { convertNewLine } from "../../../functions/convertNewLine";
import { AnswerCard } from "../../parts/cards/AnswerCard";
import { QuestionSentence } from "../../parts/questions/QuestionSentence";
import { useRecoilValue } from "recoil";
import {
  questionProcessingAnswerArray,
  questionProcessingCollectionWithQuestionArray,
  questionProcessingCurrentQuestionNumber,
} from "../../../stores/QuestionProcessingAtoms";
import { AnswerAccordion } from "../../AnswerAccordion";

export const DictationAnswer = () => {
  const collection = useRecoilValue(
    questionProcessingCollectionWithQuestionArray,
  );
  const questionArray = collection.questions;
  const answerArray = useRecoilValue(questionProcessingAnswerArray);
  const currentQuestionNumber = useRecoilValue(
    questionProcessingCurrentQuestionNumber,
  );

  let message;
  let correctFlg;
  let explanationFlg;

  if (
    compareAnswer(
      questionArray[currentQuestionNumber].answer,
      answerArray[currentQuestionNumber],
    )
  ) {
    message = "正解です！";
    correctFlg = true;
    explanationFlg = 0;
  } else {
    message = "残念！";
    correctFlg = false;
    explanationFlg = 1;
  }
  console.log(answerArray[currentQuestionNumber]);
  const formattedAnswerArray = [
    ...answerArray[currentQuestionNumber].filter(
      (item, index) => parseInt(item) !== index,
    ),
  ];

  return (
    <div className="row mt-4">
      <div className="col-md-6 col-sm-12">
        <AnswerCard headerLabel={"問題"}>
          <QuestionSentence>
            {questionArray[currentQuestionNumber].sentence}
          </QuestionSentence>
          <audio
            controls
            src={
              s3Url +
              "/" +
              questionArray[currentQuestionNumber].questionAudioPath
            }
            className="w-100 mt-2"
          />
          <div className="mt-2">
            <p className="mt-2">{"正解"}</p>
            <p className="fs-4 mt-2">
              {questionArray[currentQuestionNumber].answer.join(" ")}
            </p>
            <p className="fs-6 fst-italic">
              {questionArray[currentQuestionNumber].translatedAnswer}
            </p>
          </div>
        </AnswerCard>
      </div>
      <div className="col-md-6 col-sm-12">
        <AnswerCard headerLabel={"あなたの回答"}>
          <JudgementMessage message={message} correctFlg={correctFlg} />
          <p className="fs-4 mt-2 ">
            {formattedAnswerArray.length > 0
              ? formattedAnswerArray.join(" ")
              : "未解答"}
          </p>
          <AnswerAccordion
            header={"問題の解説"}
            body={convertNewLine(
              questionArray[currentQuestionNumber].explanation,
            )}
            activeKey={explanationFlg === 1}
          />
        </AnswerCard>
      </div>
    </div>
  );
};
