import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { DropdownMenu } from "./DropdownMenu";
import { useUsers } from "../../hooks/useUsers";
import { useCommon } from "../../hooks/useCommon";
import { CustomSpinner } from "../CustomSpinner";

export const Header = () => {
  const publicUrl = process.env.PUBLIC_URL;

  const { users, getUsers, usersLoading } = useUsers();

  const { userId } = useCommon();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  return (
    <div
      className="navbar navbar-white bg-white border-gradient"
      style={{
        borderBottom: "5px solid",
        borderImage: "linear-gradient(to right, #1ecab9 10%, #2342B5 20%) 1",
        color: "#333",
        textAlign: "center",
      }}
    >
      <div className="container">
        <div>
          <Link to="/">
            <img
              src={`${publicUrl}/header_log.png`}
              alt="Logo"
              height="32"
              className="d-inline-block align-text-top"
            />
          </Link>
        </div>
        <div className="d-flex justify-content-end">
          {usersLoading ? (
            <CustomSpinner />
          ) : (
            <DropdownMenu
              userId={userId}
              projectId={users[0]["projectId"]}
              adminFlg={users[0]["adminFlg"]}
            />
          )}
        </div>
      </div>
    </div>
  );
};
