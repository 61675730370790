import "./App.css";
import { Router } from "./routers/Router";
import { Amplify, I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { RecoilRoot } from "recoil";
import { awsExports } from "./configs/aws-exports";
import ReactGA from "react-ga4";

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

Amplify.configure(awsExports);

ReactGA.initialize("G-QZCYQJ0NQ2");

function App() {
  return (
    <>
      <RecoilRoot>
        <Router />
      </RecoilRoot>
    </>
  );
}

export default App;
// export default withAuthenticator(App);
