import React from "react";

export const SlideTitleOnly = (props) => {
  const { title, subtitle } = props;

  return (
    <div className="row min-vh-100 align-items-center">
      <div className="col" sm={12} lg={12}>
        <h2 className="text-center">{title}</h2>
        <h3 className="text-center">{subtitle}</h3>
      </div>
    </div>
  );
};
