import { useCallback, useState } from "react";
import axiosInstance from "../configs/axios";
import { awsExports } from "../configs/aws-exports";
import { useRecoilState } from "recoil";
import { questionProcessingCollectionWithQuestionArray } from "../stores/QuestionProcessingAtoms";

export const useCollectionWithQuestionArray = () => {
  const [
    collectionWithQuestionArrayLoading,
    setCollectionWithQuestionArrayLoading,
  ] = useState(true);
  const [collectionWithQuestionArray, setCollectionWithQuestionArray] =
    useRecoilState(questionProcessingCollectionWithQuestionArray);
  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getCollectionWithQuestionArray = useCallback(
    (collectionId, courseId) => {
      setCollectionWithQuestionArrayLoading(true);

      const url =
        "/collections/questions?collection_id=" +
        collectionId +
        "&course_id=" +
        courseId;
      if (collectionWithQuestionArray.collectionId !== null) {
        setCollectionWithQuestionArrayLoading(false);
        return false;
      }
      if (collectionId === null || courseId === null) {
        return false;
      }
      axiosInstance
        .get(url, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          setCollectionWithQuestionArray(res.data);
        })
        .catch((err) => console.log("初期ロードに失敗: " + err))
        .finally(() => setCollectionWithQuestionArrayLoading(false));
      // eslint-disable-next-line
    },
    [accessToken, collectionWithQuestionArray, setCollectionWithQuestionArray],
  );

  return {
    getCollectionWithQuestionArray,
    collectionWithQuestionArray,
    collectionWithQuestionArrayLoading,
  };
};
