export const getAnswerTypeJpn = (answerType) => {
  let answerTypeJpn = "問題タイプ不定";
  if (answerType === "choice") {
    answerTypeJpn = "選択問題";
  }
  if (answerType === "word" || answerType === "sentence") {
    answerTypeJpn = "書取り問題";
  }
  if (answerType === "speaking") {
    answerTypeJpn = "音読問題";
  }
  return answerTypeJpn;
};
