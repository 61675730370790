import React from "react";

export const MainArea = (props) => {
  return (
    <div
      className="container mt-3 mb-3 bg-white"
      style={{
        padding: 0,
      }}
    >
      {props.children}
    </div>
  );
};
