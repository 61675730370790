import React, { useEffect } from "react";
import { Header } from "../components/base/Header";
import { CustomSpinner } from "../components/CustomSpinner";
import { Footer } from "../components/base/Footer";
import { CardHeader } from "../components/parts/cards/CardHeader";
import { GroupList } from "../components/parts/lists/GroupList";
import { useCourses } from "../hooks/useCourses";
import { useCommon } from "../hooks/useCommon";
import { useUsers } from "../hooks/useUsers";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { Auth } from "aws-amplify";
import { useCookies } from "react-cookie";

export const HomePage = () => {
  const { getCourses, courses, coursesLoading } = useCourses();
  const { getUsers, users, usersLoading } = useUsers();

  const { userId } = useCommon();

  const location = useLocation();

  const [cookies, , removeCookie] = useCookies(["originalUrl"]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  useEffect(() => getCourses(userId), [getCourses, userId]);
  useEffect(() => getUsers(userId), [getUsers, userId]);

  let navigate = useNavigate();

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          const originalUrl = cookies.originalUrl || "/";
          removeCookie("originalUrl", { path: "/" });
          navigate(originalUrl, { replace: true });
        }
      } catch (error) {
        // ユーザーが認証されていない場合、何もしない
      }
    };

    checkAuthAndRedirect().then((r) =>
      console.log("checkAuthAndRedirect: ", r),
    );
  }, [navigate, removeCookie, cookies.originalUrl]);

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="container main">
        {usersLoading ? (
          <CustomSpinner />
        ) : users[0]["initialSettingFlg"] ? (
          coursesLoading ? (
            <CustomSpinner />
          ) : (
            <div className="card mt-4 mb-4">
              <CardHeader title="動画シリーズ一覧" />
              <div className="row">
                {courses.length > 0 ? (
                  <div className="col">
                    <GroupList
                      array={courses.map((course) => {
                        return {
                          id: course.courseId,
                          label: course.courseName,
                          status: course.status,
                          link: `/course?course_id=${course.courseId}`,
                        };
                      })}
                    />
                  </div>
                ) : (
                  <div className="col">
                    <p>問題がなし</p>
                  </div>
                )}
              </div>
            </div>
          )
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
};
