import React, { useEffect, useState } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { Header } from "../../components/base/Header";
import { useUserStats } from "../../hooks/stats/useUserStats";
import { useUserWeeklyStats } from "../../hooks/stats/useUserWeeklyStats";
import { CustomSpinner } from "../../components/CustomSpinner";
import { formatDateForChartLabel } from "../../functions/formatDateForChartLabel";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import ReactGA from "react-ga4";
import {useUsers} from "../../hooks/useUsers";

const getLastMondays = () => {
  const result = [];
  const date = new Date(); // 現在の日付を取得
  const dayOfWeek = date.getDay(); // 曜日を取得 (0 = Sunday, 1 = Monday, ...)
  const prevMonday = new Date(date);

  // 先週の月曜日を設定
  prevMonday.setDate(date.getDate() - ((dayOfWeek + 6) % 7) - 7);

  // 過去1ヶ月間の月曜日を取得
  for (let i = 0; i < 4; i++) {
    result.push(formatDate(prevMonday));
    prevMonday.setDate(prevMonday.getDate() - 7); // 一週間前の月曜日に設定
  }

  return result.reverse();
};

const formatDate = (date) => {
  let month = "" + (date.getMonth() + 1);
  let day = "" + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const UserStatsPage = () => {
  // projectIdをクエリパラメータから取得
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("user_id");
  const [data, setData] = useState({});
  const [options, setOptions] = useState({});

  let navigate = useNavigate();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const { getUserStats, userStats, userStatsLoading } = useUserStats();
  const { getUserWeeklyStats, userWeeklyStats, userWeeklyStatsLoading } =
    useUserWeeklyStats();
  useEffect(() => getUserStats(userId), [getUserStats, userId]);
  useEffect(() => getUserWeeklyStats(userId), [getUserWeeklyStats, userId]);
  useEffect(() => {
    const mondayList = getLastMondays();
    const learningCollectionCount = [];
    const completeCollectionCount = [];
    let userWeeklyStatsIsEmptyFlg = true;
    mondayList.forEach((monday) => {
      const data = userWeeklyStats.find((item) => item.startDate === monday);
      if (data) {
        learningCollectionCount.push(data.learningCollectionCount);
        completeCollectionCount.push(data.completeCollectionCount);
        userWeeklyStatsIsEmptyFlg = false;
      } else {
        learningCollectionCount.push(0);
        completeCollectionCount.push(0);
      }
    });

    const labels = mondayList.map(
      (data) => formatDateForChartLabel(data) + "週",
    );
    setData({
      labels: labels, // X軸のラベル
      datasets: [
        {
          label: "週間学習数",
          data: learningCollectionCount,
          backgroundColor: "rgba(255, 99, 132, 0.5)", // バーの色
          yAxisID: "y-axis-1", // Y軸のID
        },
        {
          label: "週間完了数",
          data: completeCollectionCount,
          backgroundColor: "rgba(54, 162, 235, 0.5)", // バーの色
          yAxisID: "y-axis-1", // 同じY軸を使用
        },
      ],
    });

    if (userWeeklyStatsIsEmptyFlg) {
      setOptions({
        scales: {
          "y-axis-1": {
            type: "linear",
            display: true,
            position: "right",
            title: {
              text: "学習回数",
              display: true,
            },
            max: 100,
            min: 0,
          },
        },
      });
    } else {
      setOptions({
        scales: {
          "y-axis-1": {
            type: "linear",
            display: true,
            position: "right",
            title: {
              text: "学習回数",
              display: true,
            },
          },
        },
      });
    }
  }, [userWeeklyStats]);

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="container">
        <div className="card p-3 mt-4 mb-2">
          <div className="mb-3">
            <h2>週間学習履歴</h2>
          </div>
          <div className="row mb-3">
            {userStatsLoading ? (
              <CustomSpinner />
            ) : userStats.length === 0 ? (
              <p>学習履歴はありません。</p>
            ) : (
              <>
                <div className="col-xs-12 col-sm-6 mb-2">
                  <div className="card">
                    <div className="card-body">
                      <span>視聴回数</span>
                      <p className="card-text fw-bold fs-1">
                        {userStats["totalQuestion"]}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 mb-2">
                  <div className="card">
                    <div className="card-body">
                      <span>視聴完了数</span>
                      {userStatsLoading ? (
                        <CustomSpinner />
                      ) : (
                        <p className="card-text fw-bold fs-1">
                          {userStats["completeCount"]}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="row mb-3">
            {userWeeklyStatsLoading ? (
              <CustomSpinner />
            ) : (
              <Bar data={data} options={options} />
            )}
          </div>
        </div>
        <div className="py-4">
          <Link to={"/"}>トップページに戻る</Link>
        </div>
      </div>
    </div>
  );
};
