import React, { useEffect, useMemo, useRef } from "react";
import { compareAnswer } from "../../../functions/compareAnswer";
import { s3Url } from "../../../configs/common";
import { JudgementMessage } from "../../JudgmentMessage";
import { IoIosRadioButtonOff, IoIosClose } from "react-icons/io";
import { convertNewLine } from "../../../functions/convertNewLine";
import { AnswerCard } from "../../parts/cards/AnswerCard";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  questionProcessingAnswerArray,
  questionProcessingCollectionWithQuestionArray,
  questionProcessingCurrentQuestionNumber,
} from "../../../stores/QuestionProcessingAtoms";
import { AnswerAccordion } from "../../AnswerAccordion";
import Hls from "hls.js";

export const ChoiceAnswer = () => {
  const collection = useRecoilValue(
    questionProcessingCollectionWithQuestionArray,
  );
  const questionArray = collection.questions;
  const currentQuestionNumber = useRecoilValue(
    questionProcessingCurrentQuestionNumber,
  );
  const [answerArray, setAnswerArray] = useRecoilState(
    questionProcessingAnswerArray,
  );

  let correctFlg;
  let correctAnswerText = "";
  let explanationFlg;
  const answerNumber = [
    ...questionArray[currentQuestionNumber].answer.map((item) => item + 1),
  ];

  const isSupportBrowser = useMemo(() => Hls.isSupported(), []);
  const videoRef = useRef(null);

  if (answerArray[currentQuestionNumber] === undefined) {
    setAnswerArray([...answerArray, []]);
  }
  if (questionArray[currentQuestionNumber].answer.length > 1) {
    if (
      compareAnswer(
        questionArray[currentQuestionNumber].answer,
        answerArray[currentQuestionNumber],
      )
    ) {
      correctFlg = true;
      explanationFlg = 0;
    } else {
      correctAnswerText = "正解は" + answerNumber;
      correctFlg = false;
      explanationFlg = 1;
    }
  } else {
    if (
      questionArray[currentQuestionNumber].answer[0] ===
      parseInt(answerArray[currentQuestionNumber])
    ) {
      correctFlg = true;
      explanationFlg = 0;
    } else {
      correctAnswerText = "ChoiceAnswer.正解は" + answerNumber;
      correctFlg = false;
      explanationFlg = 1;
    }
  }

  useEffect(() => {
    console.log("isSupportBrowser: " + isSupportBrowser);
    if (isSupportBrowser) {
      let hls = new Hls();
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(
          s3Url + "/" + questionArray[currentQuestionNumber].questionVideoPath,
        );
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            "manifest loaded, found " + data.levels.length + " quality level",
          );
          hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // try to recover network error
                  console.log(
                    "fatal network error encountered, try to recover",
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("fatal media error encountered, try to recover");
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
          });
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row mt-4">
      <div className="col-md-6 col-sm-12">
        <AnswerCard headerLabel={"問題"}>
          {questionArray[currentQuestionNumber].questionImagePath === null ? (
            <></>
          ) : (
            <div>
              <img
                src={
                  s3Url +
                  "/" +
                  questionArray[currentQuestionNumber].questionImagePath
                }
                alt={"question"}
                className={"w-100"}
              />
            </div>
          )}
          <p className="mt-3">
            {convertNewLine(questionArray[currentQuestionNumber].sentence)}
          </p>
          {questionArray[currentQuestionNumber].questionAudioPath === null ? (
            <></>
          ) : (
            <div className="mt-3">
              <audio
                controls
                src={
                  s3Url +
                  "/" +
                  questionArray[currentQuestionNumber].questionAudioPath
                }
                className="w-100 my-6"
              />
            </div>
          )}
          {questionArray[currentQuestionNumber].questionVideoPath === null ? (
            <></>
          ) : (
            <div className="mt-3">
              {isSupportBrowser ? (
                <video
                  controls
                  muted
                  className="w-100"
                  ref={videoRef}
                />
              ) : (
                <video
                  controls
                  muted
                  className="w-100"
                  src={questionArray[currentQuestionNumber].questionVideoPath}
                />
              )}
            </div>
          )}
        </AnswerCard>
      </div>
      <div className="col-md-6 col-sm-12">
        <AnswerCard headerLabel={"ChoiceAnswer.あなたの回答"}>
          <JudgementMessage
            correctAnswerText={correctAnswerText}
            correctFlg={correctFlg}
          />
          {answerArray[currentQuestionNumber] === undefined ? (
            <></>
          ) : (
            <div className="list-group-numbered">
              {questionArray[currentQuestionNumber].choices.map(
                (item, choiceIndex) => {
                  if (questionArray[currentQuestionNumber].answer.length > 1) {
                    return (
                      <div
                        key={choiceIndex}
                        className={`mb-2 p-2 d-flex align-items-center`}
                        style={{
                          border: "1px solid #E5E5E5",
                          borderRadius: "6px",
                          font: "normal normal normal 14px/17px Yu Gothic UI",
                          color: questionArray[
                            currentQuestionNumber
                          ].answer.includes(choiceIndex)
                            ? "#1ECAB9"
                            : answerArray[currentQuestionNumber].includes(
                                  choiceIndex,
                                )
                              ? "#FFFFFF"
                              : "#363636",
                          borderColor: questionArray[
                            currentQuestionNumber
                          ].answer.includes(choiceIndex)
                            ? "#1ECAB9"
                            : "#E5E5E5",
                          backgroundColor: questionArray[
                            currentQuestionNumber
                          ].answer.includes(choiceIndex)
                            ? ""
                            : answerArray[currentQuestionNumber].includes(
                                  choiceIndex,
                                )
                              ? "#FE6851"
                              : "",
                        }}
                      >
                        <div className="float-start me-2">
                          {questionArray[currentQuestionNumber].answer.includes(
                            choiceIndex,
                          ) ? (
                            <IoIosRadioButtonOff
                              className="me-1 text-center"
                              style={{
                                color: "#1ECAB9",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          ) : answerArray[currentQuestionNumber].includes(
                              choiceIndex,
                            ) ? (
                            <IoIosClose
                              className={`me-1 text-center`}
                              style={{
                                color: "#FFFFFF",
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          )}
                        </div>
                        {item}
                      </div>
                    );
                  } else {
                    if (item === "") {
                      return <div key={choiceIndex} />;
                    } else {
                      return (
                        <div
                          key={choiceIndex}
                          style={{
                            border: "1px solid #E5E5E5",
                            borderRadius: "6px",
                            font: "normal normal normal 14px/17px Yu Gothic UI",
                            color: questionArray[
                              currentQuestionNumber
                            ].answer.includes(choiceIndex)
                              ? "#1ECAB9"
                              : answerArray[currentQuestionNumber] ===
                                  choiceIndex
                                ? "#FFFFFF"
                                : "#363636",
                            borderColor: questionArray[
                              currentQuestionNumber
                            ].answer.includes(choiceIndex)
                              ? "#1ECAB9"
                              : "#E5E5E5",
                            backgroundColor: questionArray[
                              currentQuestionNumber
                            ].answer.includes(choiceIndex)
                              ? ""
                              : answerArray[currentQuestionNumber] ===
                                  choiceIndex
                                ? "#FE6851"
                                : "",
                          }}
                          className={`mb-2 p-2 d-flex align-items-center`}
                        >
                          <div className="float-start">
                            {questionArray[
                              currentQuestionNumber
                            ].answer.includes(choiceIndex) ? (
                              <IoIosRadioButtonOff
                                className="me-1 text-center"
                                style={{
                                  color: "#1ECAB9",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            ) : answerArray[currentQuestionNumber] ===
                              choiceIndex ? (
                              <IoIosClose
                                className={`me-1 text-center`}
                                style={{
                                  color: "#FFFFFF",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            )}
                          </div>
                          {item}
                        </div>
                      );
                    }
                  }
                },
              )}
            </div>
          )}
          <AnswerAccordion
            header={"問題の解説"}
            body={convertNewLine(
              questionArray[currentQuestionNumber].explanation,
            )}
            activeKey={explanationFlg === 1}
          />
        </AnswerCard>
      </div>
    </div>
  );
};
