import React from "react";

export const SlideResult = () => {
  return (
    <div className="mt-4 mb-2 px-4">
      <p
        style={{
          font: "normal normal normal 14px/17px Yu Gothic",
          letterSpacing: "0px",
          color: "#363636",
        }}
      >
        {"問題を解いて理解度を確認しましょう。"}
      </p>
    </div>
  );
};
