import { useCallback, useState } from "react";
import axiosInstance from "../../configs/axios";
import { awsExports } from "../../configs/aws-exports";

export const useProjectUserStats = () => {
  const [projectUserStatsLoading, setProjectUserStatsLoading] = useState(true);
  const [projectUserStats, setProjectUserStats] = useState([]);

  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getProjectUserStats = useCallback((projectId) => {
    setProjectUserStatsLoading(true);
    let url = "/project-user-stats?project_id=" + projectId;
    axiosInstance
      .get(url, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => setProjectUserStats(res.data))
      .catch((err) => console.log("初期ロードに失敗: " + err))
      .finally(() => setProjectUserStatsLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    getProjectUserStats,
    projectUserStats,
    projectUserStatsLoading,
  };
};
