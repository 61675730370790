import { Footer } from "../components/base/Footer";
import { SignOut } from "../components/SignOut";

export const ErrorFallback = (error) => {
  return (
    <div className="bg-light min-vh-100">
      <div className="container main">
        <div className="row gx-5">
          <div className="mt-5 text-center">
            <h2>エラーが発生しました。</h2>
            <p>
              エラーが発生しました。お手数ですが、再度ログインをお試しください。
            </p>
            <button className="btn btn-secondary" onClick={SignOut}>
              ログアウト
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
