import React from "react";

export const ProgressBar = (props) => {
  const { count, totalCount } = props;

  const progressBar = Math.floor((count / totalCount) * 100);

  return (
    <div className="d-flex justify-content-between align-items-center mt-3 px-4 pt-4">
      <div
        className="progress"
        style={{
          width: "85%",
        }}
      >
        <div
          className="progress-bar"
          role="progressbar"
          style={{
            width: `${progressBar}%`,
          }}
          aria-valuenow={progressBar}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <div
        style={{
          width: "15%",
          textAlign: "center",
          font: "normal normal bold 12px/14px Yu Gothic",
        }}
      >
        {count}/{totalCount}
      </div>
    </div>
  );
};
