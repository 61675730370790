import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export const FullScreenModal = ({ title, children }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className="text-primary pointer-target" onClick={handleShow}>
        <u>こちら</u>
      </span>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-fullscreen"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
      </Modal>
    </>
  );
};
