import { useCallback, useState } from "react";
import axiosInstance from "../../configs/axios";
import { awsExports } from "../../configs/aws-exports";

export const useProjectUsage = () => {
  const [projectUsageLoading, setProjectUsageLoading] = useState(true);
  const [projectUsage, setProjectUsage] = useState([]);
  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getProjectUsage = useCallback((projectId) => {
    setProjectUsageLoading(true);
    const url = "/project-usage?project_id=" + projectId;
    axiosInstance
      .get(url, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => setProjectUsage(res.data))
      .catch((err) => console.log("初期ロードに失敗: " + err))
      .finally(() => setProjectUsageLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    getProjectUsage,
    projectUsage,
    projectUsageLoading,
  };
};
