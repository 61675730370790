import React from "react";
import { convertNewLine } from "../../../functions/convertNewLine";

export const SlideStandardImage = (props) => {
  const { title, subtitle, sentence, imageFileUrl, audioFileUrl } = props;

  return (
    <div className="row align-items-end">
      <div className="col" sm={12} lg={6}>
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        <img className="img-fluid" alt="" src={imageFileUrl} />
      </div>
      <div className="col" sm={12} lg={6}>
        <div className="card">
          <div className="card-body">
            {audioFileUrl === undefined ? (
              <></>
            ) : (
              <div className="my-2">
                <audio controls src={audioFileUrl} className="w-100 my-6" />
              </div>
            )}
            {convertNewLine(sentence)}
          </div>
        </div>
      </div>
    </div>
  );
};
