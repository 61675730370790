import React from "react";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useResetRecoilState } from "recoil";
import {
  questionProcessingCollectionHistoryId,
  questionProcessingCollectionId,
  questionProcessingCollectionWithQuestionArray,
  questionProcessingCourseId,
  questionProcessingCurrentQuestionNumber,
  questionProcessingAnswerArray,
} from "../../../stores/QuestionProcessingAtoms";

export const QuestionBackButton = (props) => {
  const { courseId } = props;

  const resetQuestionProcessingCollectionWithQuestionArray =
    useResetRecoilState(questionProcessingCollectionWithQuestionArray);
  const resetQuestionProcessingCurrentQuestionNumber = useResetRecoilState(
    questionProcessingCurrentQuestionNumber,
  );
  const resetQuestionProcessingCourseId = useResetRecoilState(
    questionProcessingCourseId,
  );
  const resetQuestionProcessingCollectionId = useResetRecoilState(
    questionProcessingCollectionId,
  );
  const resetQuestionProcessingCollectionHistoryId = useResetRecoilState(
    questionProcessingCollectionHistoryId,
  );
  const resetQuestionProcessingAnswerArray = useResetRecoilState(
    questionProcessingAnswerArray,
  );
  let navigate = useNavigate();
  const confirmBackPage = () => {
    if (window.confirm("問題を終了しますか？")) {
      resetQuestionProcessingCollectionWithQuestionArray();
      resetQuestionProcessingCurrentQuestionNumber();
      resetQuestionProcessingCourseId();
      resetQuestionProcessingCollectionId();
      resetQuestionProcessingCollectionHistoryId();
      resetQuestionProcessingAnswerArray();
      navigate("/course?course_id=" + courseId);
    } else {
      return false;
    }
  };

  return (
    <p
      className="d-flx"
      onClick={confirmBackPage}
      style={{
        cursor: "pointer",
        display: "inline",
      }}
    >
      <IoIosArrowDropleftCircle
        className="me-1"
        style={{
          width: "30px",
          height: "30px",
          color: "#2342B5",
        }}
      />
      <span
        style={{
          font: "normal normal bold 12px/14px Yu Gothic",
          color: "#333333",
        }}
      >
        {"戻る"}
      </span>
    </p>
  );
};
