import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

export const QuestionLayout = () => {
  const onUnload = (e) => {
    e.preventDefault();
    e.returnValue =
      "現在解いている問題がある場合、初期化されてしまいます。よろしいですか？";
  };

  const blockBrowserBack = (e) => {
    e.preventDefault();
    window.history.go(1);
  };

  useEffect(() => {
    // ブラウザバックを禁止する
    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", blockBrowserBack);
    // リロードとタブを閉じるイベントを検知
    window.addEventListener("beforeunload", onUnload);

    // クリーンアップ
    return () => {
      window.removeEventListener("popstate", blockBrowserBack);
      window.removeEventListener("beforeunload", onUnload);
    };
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};
