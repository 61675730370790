import React, { useEffect, useState } from "react";
import { useCommon } from "../../hooks/useCommon";
import { awsExports } from "../../configs/aws-exports";
import axiosInstance from "../../configs/axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { publicUrl } from "../../configs/common";
import { FullScreenModal } from "../../components/parts/FullScreenModal";
import { UsagePolicyContent } from "../../components/UsagePolicyContent";
import { PrivacyPolicyContent } from "../../components/PrivacyPolicyContent";
import ReactGA from "react-ga4";
import {useLocation, useNavigate} from "react-router-dom";
import {useUsers} from "../../hooks/useUsers";

export const InitialSettingPage = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const { userId } = useCommon();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [username, setUsername] = useState("");
  const [modalDisplayFlg, setModalDisplayFlg] = useState(false);
  const [agreeCheck, setAgreeCheck] = useState(false);

  const handleModalClose = () => setModalDisplayFlg(false);
  const handleModalShow = () => {
    if (username === "") {
      alert("氏名を入力してください。");
      return;
    }

    if (projectName === "") {
      alert("正しい医院IDを入力してください。");
      return;
    }
    setModalDisplayFlg(true);
  };

  const onClickCheckProjectPasscode = async (e) => {
    if (e.target.value.length === 6) {
      const data = {
        projectPasscode: e.target.value,
      };
      // postLogging(data);
      await axiosInstance
        .post("/check-project-passcode", data, {
          headers: {
            Authorization: accessToken,
          },
        })
        .then((res) => {
          if (res.data.currentCount >= res.data.maxCount) {
            alert("この医院IDは利用者数上限を超えています。");
            return;
          }
          setProjectId(res.data.projectId);
          setProjectName(res.data.projectName);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            alert("医院IDが間違っています。");
          } else {
            alert("Error: " + err);
          }
        });
    } else {
      setProjectId(null);
      setProjectName("");
    }
  };

  const onClickInitialSetting = async () => {
    const data = {
      userId: userId,
      username: username,
      projectId: projectId,
    };
    // postLogging(data);
    await axiosInstance
      .post("/initial-setting", data, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => {
        window.location.href = "/";
      })
      .catch((err) => alert("Error: " + err));
  };

  return (
    <div className="bg-white">
      <div className="py-3 container">
        <div className="mb-3">
          <div className="d-flex justify-content-center">
            <img
              src={`${publicUrl}/header_log.png`}
              alt="Logo"
              className="img-fluid"
            />
          </div>
          <p className="text-center">初期登録フォーム</p>
        </div>
        <div className="mb-3">
          <label id="username" className="form-label fw-bold">
            氏名
          </label>
          <input
            type="text"
            id="username"
            className="form-control"
            placeholder="(例)山田 太郎"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <p>6桁の医院のパスコードを入力してください。</p>
        </div>
        <div className="mb-3">
          <label id="projectPasscode" className="form-label fw-bold">
            医院ID
            <span> ※ご不明な場合は院長や管理者にご確認ください</span>
          </label>
          <input
            type="text"
            id="projectPasscode"
            className="form-control"
            placeholder="(例)abc123"
            maxLength="6"
            onChange={(e) => onClickCheckProjectPasscode(e)}
          />
          {projectName ? (
            <div className="my-3">
              <p>医院名: {projectName}</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="mb-3">
          <p className="fw-bold">利用規約・プライバシーポリシー</p>
          <p>『歯科LINE』</p>
          <p>
            利用規約は
            <FullScreenModal title="利用規約">
              <UsagePolicyContent />
            </FullScreenModal>{" "}
          </p>
          <p>
            プライバシーポリシーは
            <FullScreenModal title="プライバシーポリシー">
              <PrivacyPolicyContent />
            </FullScreenModal>{" "}
          </p>
          <p>上記利用規約・プライバシーポリシーに同意の上お進みください。</p>
          <div className="form-check">
            <input
              className="form-check-input service-color-checkbox"
              type="checkbox"
              checked={agreeCheck}
              onChange={() => setAgreeCheck(!agreeCheck)}
              id="flexCheckDefault"
            />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              利用規約・プライバシーポリシーに同意する
            </label>
          </div>
        </div>
        <div className="mb-3">
          <button
            disabled={!agreeCheck}
            type="button"
            className="btn service-color-btn w-100"
            onClick={handleModalShow}
          >
            入力完了
            <br />
            (確認画面へ)
          </button>
        </div>
      </div>
      <Modal show={modalDisplayFlg} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">確認画面</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>氏名：{username}</p>
          <p>医院名：{projectName}</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="modal-button"
            variant="secondary"
            onClick={handleModalClose}
          >
            修正する
          </Button>
          <Button
            className="modal-button service-color-btn"
            onClick={onClickInitialSetting}
          >
            登録を
            <br />
            完了する
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
