import axiosInstance from "../configs/axios";
import { awsExports } from "../configs/aws-exports";

export const PostLog = async (data) => {
  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  await axiosInstance
    .post("/logging/question", data, {
      headers: {
        Authorization: accessToken,
      },
    })
    .then((res) => console.log(res.data))
    .catch((err) => alert("Error: " + err));
};
