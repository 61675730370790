import React from "react";
import { Auth } from "aws-amplify";
import {
  Authenticator,
  Button,
  Image,
  Text,
  View,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

export const SingUpAndSignInPage = () => {
  const components = {
    SignIn: {
      Header() {
        return (
          <View padding="2rem">
            <div className="mb-3 text-center">
              <Image
                alt="logo"
                src="./icon.png"
                objectFit="initial"
                objectPosition="50% 50%"
                height="50%"
                width="50%"
              />
            </div>
            <div className="mb-3">
              <Text>
                より良いクリニック環境と経営のための動画研修サービス『歯科LINE』へようこそ！
              </Text>
              <br />
              <Text>初期登録は氏名と医院IDを入力するだけです。</Text>
            </div>
            <div className="mt-4 mb-3">
              <Button
                variation="primary"
                className="service-color"
                isFullWidth={true}
                onClick={() => Auth.federatedSignIn({ provider: "LINE" })}
              >
                <div className="d-flex flex-column align-items-center">
                  <span className="fs-4 d-block">
                    『歯科LINE』初期登録フォームへ
                  </span>
                </div>
              </Button>
              <div className="mt-2">
                <span className="button-sub-label">
                  (LINEログイン後、お申し込みフォームに遷移します)
                </span>
              </div>
            </div>
            <div>
              <Text>
                ※入力は初回のみです。2回目以降はログイン等も必要ございません。
              </Text>
            </div>
            <div>
              <Text>
                ※初期登録がお済みの方は
                <span
                  className="text-primary text-decoration-underline"
                  style={{ cursor: "pointer" }}
                  onClick={() => Auth.federatedSignIn({ provider: "LINE" })}
                >
                  こちら
                </span>
              </Text>
            </div>
          </View>
        );
      },
      Footer() {
        return <></>;
      },
    },
  };

  return (
    <Authenticator
      loginMechanisms={["email"]}
      hideSignUp={true}
      variation="modal"
      components={components}
    >
      {({ signOut }) => {
        return (
          <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">認証済みページ</h1>
            <button
              onClick={signOut}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
            >
              サインアウト
            </button>
          </div>
        );
      }}
    </Authenticator>
  );
};
