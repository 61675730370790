import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { QuestionLayout } from "../layouts/QuestionLayout";
import { BasicLayout } from "../layouts/BasicLayout";
import { HomePage } from "../pages/HomePage";
import { QuestionAnswerPage } from "../pages/question/QuestionAnswerPage";
import { CollectionResultPage } from "../pages/collection/CollectionResultPage";
import { QuestionPage } from "../pages/question/QuestionPage";
import { CoursePage } from "../pages/course/CoursePage";
import { ProjectStatsPage } from "../pages/stats/ProjectStatsPage";
import { UserSettingPage } from "../pages/setting/UserSettingPage";
import { UserStatsPage } from "../pages/stats/UserStatsPage";
import { InitialSettingPage } from "../pages/setting/InitialSettingPage";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../components/ErrorFallback";
import { ProjectUsageIndexPage } from "../pages/usage/ProjectUsageIndexPage";
import {
  Logger as AmplifyLogger,
  AWSCloudWatchProvider,
  Amplify,
} from "aws-amplify";
import { ProtectedRouter } from "./ProtectRouter";
import { SingUpAndSignInPage } from "../pages/SingUpAndSignInPage";

export const Router = () => {
  const onError = (error, info) => {
    // errorログ
    console.log("Error boundary", error.message);
    console.log("Error boundary", info.componentStack);
    // cloudWatchにエラーを出力
    const awsLogger = new AmplifyLogger("log-prefix", "INFO");
    Amplify.register(awsLogger);
    awsLogger.addPluggable(new AWSCloudWatchProvider());
    awsLogger.error("Error boundary", error.message, info.componentStack);
  };

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
        <Routes>
          <Route path="/signin" element={<SingUpAndSignInPage />} />
          <Route element={<ProtectedRouter />}>
            <Route element={<QuestionLayout />}>
              <Route path="/question" element={<QuestionPage />} />
              <Route path="/answer" element={<QuestionAnswerPage />} />
              <Route path="/result" element={<CollectionResultPage />} />
            </Route>
            <Route element={<BasicLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/initial-setting" element={<InitialSettingPage />} />
              <Route path="/course" element={<CoursePage />} />
              <Route path="/usage" element={<ProjectUsageIndexPage />} />
              <Route path="/user-setting" element={<UserSettingPage />} />
              <Route path="/stats/project" element={<ProjectStatsPage />} />
              <Route path="/stats/user" element={<UserStatsPage />} />
            </Route>
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
