import React, { useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";

import { Header } from "../../components/base/Header";
import { Footer } from "../../components/base/Footer";
import { MainArea } from "../../components/base/MainArea";
import { ChoiceQuestion } from "../../components/question/choice/ChoiceQuestion";
import { CustomSpinner } from "../../components/CustomSpinner";
import { SlideMain } from "../../components/question/slide/SlideMain";
import { DictationQuestion } from "../../components/question/dictation/DictationQuestion";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  questionProcessingCollectionId,
  questionProcessingCourseId,
  questionProcessingCurrentQuestionNumber,
} from "../../stores/QuestionProcessingAtoms";
import { useLogging } from "../../hooks/useLogging";
import { useCollectionWithQuestionArray } from "../../hooks/useCollectionWithQuestionArray";
import { QuestionBackButton } from "../../components/parts/buttons/QuestionBackButton";
import { useCommon } from "../../hooks/useCommon";
import ReactGA from "react-ga4";
import {useUsers} from "../../hooks/useUsers";

export const QuestionPage = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const search = location.search;
  const query = new URLSearchParams(search);
  const queryCollectionId = parseInt(query.get("collection_id"));
  const queryCourseId = parseInt(query.get("course_id"));
  const { userId } = useCommon();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const [courseId, setCourseId] = useRecoilState(questionProcessingCourseId);
  const [collectionId, setCollectionId] = useRecoilState(
    questionProcessingCollectionId,
  );
  const currentQuestionNumber = useRecoilValue(
    questionProcessingCurrentQuestionNumber,
  );

  if (courseId === null || queryCourseId !== courseId) {
    setCourseId(queryCourseId);
  }
  if (collectionId === null || queryCollectionId !== collectionId) {
    setCollectionId(queryCollectionId);
  }

  const { getCollectionHistoryId, loggingLoading } = useLogging();
  const {
    collectionWithQuestionArray,
    getCollectionWithQuestionArray,
    collectionWithQuestionArrayLoading,
  } = useCollectionWithQuestionArray();

  useEffect(
    () => getCollectionHistoryId(userId, collectionId, courseId),
    [getCollectionHistoryId, userId, collectionId, courseId],
  );

  useEffect(
    () => getCollectionWithQuestionArray(collectionId, courseId),
    [getCollectionWithQuestionArray, collectionId, courseId],
  );

  const questionsNumber = {
    questionsLength:
      collectionWithQuestionArray.questions.length > 0
        ? collectionWithQuestionArray.questions.length
        : 0,
    questionNumber: currentQuestionNumber,
  };

  const selectedQA = (question) => {
    if (question.answerType === "choice") {
      return (
        <ChoiceQuestion
          question={question}
          questionsNumber={questionsNumber}
          userId={userId}
        />
      );
    }
    if (question.answerType === "word" || question.answerType === "sentence") {
      return (
        <DictationQuestion
          question={question}
          questionsNumber={questionsNumber}
          userId={userId}
        />
      );
    }
    if (question.screenType === "slide") {
      return (
        <SlideMain
          question={question}
          questionsNumber={questionsNumber}
          userId={userId}
        />
      );
    }
    return (
      <p>
        {"問題タイプが不正です。"}Type: {question.slide_type}
      </p>
    );
  };

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="main">
        <MainArea>
          {collectionWithQuestionArrayLoading ||
          loggingLoading ||
          collectionWithQuestionArray.length === 0 ? (
            <CustomSpinner />
          ) : (
            selectedQA(
              collectionWithQuestionArray.questions[currentQuestionNumber],
            )
          )}
        </MainArea>
      </div>
      <div className="container mb-4">
        <QuestionBackButton courseId={courseId} />
      </div>
      <Footer />
    </div>
  );
};
