import React from "react";
import { convertNewLine } from "../../../functions/convertNewLine";

export const QuestionSentence = (props) => {
  return (
    <div className="mt-3 px-3 pb-3">
      <p
        style={{
          font: "normal normal bold 14px/17px Yu Gothic",
          letterSpacing: "0px",
          color: "#363636",
        }}
      >
        {convertNewLine(props.children)}
      </p>
    </div>
  );
};
