import React, { useEffect } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { Header } from "../../components/base/Header";

import { CustomSpinner } from "../../components/CustomSpinner";
import { useProjectUsage } from "../../hooks/usage/useProjectUsage";
import ReactGA from "react-ga4";
import {useCommon} from "../../hooks/useCommon";
import {useUsers} from "../../hooks/useUsers";

export const ProjectUsageIndexPage = () => {
  // projectIdをクエリパラメータから取得
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("project_id");
  let navigate = useNavigate();
  const { userId } = useCommon();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
  const { getProjectUsage, projectUsage, projectUsageLoading } =
    useProjectUsage();

  useEffect(() => {
    getProjectUsage(projectId);
  }, [getProjectUsage, projectId]);

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="container">
        <div className="card p-3 mt-3 mb-3">
          <div className="mb-3">
            <h2>利用状況</h2>
          </div>
          <div className="row mb-3">
            <div className="col col-lg-12">
              <table className="table">
                {projectUsageLoading ? (
                  <CustomSpinner />
                ) : (
                  <tbody>
                    <tr>
                      <th>プラン</th>
                      <td>{projectUsage["plan"]}</td>
                    </tr>
                    <tr>
                      <th>現在の利用人数</th>
                      <td>
                        {projectUsage["currentCount"]}人(残:
                        {projectUsage["maxCount"] -
                          projectUsage["currentCount"]}
                        人)
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <div className="py-4">
          <Link to={"/"}>トップページに戻る</Link>
        </div>
      </div>
    </div>
  );
};
