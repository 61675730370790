import React, { useEffect, useMemo, useRef } from "react";
import Hls from "hls.js";
import { convertNewLine } from "../../../functions/convertNewLine";

export const SlideStandardVideo = (props) => {
  const { title, subtitle, sentence, videoFileUrl } = props;

  const isSupportBrowser = useMemo(() => Hls.isSupported(), []);
  const videoRef = useRef(null);

  console.log(videoFileUrl);
  useEffect(() => {
    console.log("isSupportBrowser: " + isSupportBrowser);
    if (isSupportBrowser) {
      let hls = new Hls();
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(videoFileUrl);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            "manifest loaded, found " + data.levels.length + " quality level",
          );
          hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // try to recover network error
                  console.log(
                    "fatal network error encountered, try to recover",
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("fatal media error encountered, try to recover");
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
          });
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="row align-items-end">
      <div className="col-sm-12 col-lg-6">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
        {isSupportBrowser ? (
          <video controls muted className="w-100" ref={videoRef} />
        ) : (
          <video controls muted className="w-100" src={videoFileUrl} />
        )}
      </div>
      <div className="col-sm-12 col-lg-6">
        <div className="card">
          <div className="card-body">{convertNewLine(sentence)}</div>
        </div>
      </div>
    </div>
  );
};
