import React from "react";
import { IoIosRadioButtonOff, IoIosClose } from "react-icons/io";

export const JudgementMessage = (props) => {
  const { correctFlg, correctAnswerText } = props;

  return (
    <div
      className="flex py-3 mb-3"
      style={{
        backgroundColor: correctFlg ? "#E3FBF8" : "#FFEDEB",
      }}
    >
      <h2
        className="text-center"
        style={{
          font: "normal normal bold 28px/34px Yu Gothic",
        }}
      >
        {correctFlg ? "正解！" : "残念！"}
        <br />
        {correctFlg ? (
          <IoIosRadioButtonOff
            style={{
              fontSize: "6rem",
              color: "#1ECAB9",
            }}
          />
        ) : (
          <IoIosClose
            style={{
              fontSize: "6rem",
              color: "#FE6851",
            }}
          />
        )}
        <br />
        <span className="text-danger fw-bold">{correctAnswerText}</span>
      </h2>
    </div>
  );
};
