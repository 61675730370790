import React, { useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { CardHeader } from "../../components/parts/cards/CardHeader";
import { Header } from "../../components/base/Header";
import { Footer } from "../../components/base/Footer";
import { CustomSpinner } from "../../components/CustomSpinner";
import { BackBottom } from "../../components/parts/buttons/BackButton";
import { GroupList } from "../../components/parts/lists/GroupList";
import { useCollections } from "../../hooks/useCollections";
import { useCommon } from "../../hooks/useCommon";
import { useCourses } from "../../hooks/useCourses";
import ReactGA from "react-ga4";
import {useUsers} from "../../hooks/useUsers";

export const CoursePage = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const courseId = query.get("course_id");
  const location = useLocation();
  let navigate = useNavigate();
  const { userId } = useCommon();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const { getCollections, collections, collectionsLoading } = useCollections();

  const { getCourses, courses, coursesLoading } = useCourses();

  useEffect(
    () => getCollections(userId, courseId),
    [getCollections, userId, courseId],
  );
  useEffect(() => getCourses(userId), [getCourses, userId]);

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="container main">
        <div className="card mt-4 mb-4">
          {coursesLoading ? (
            <CustomSpinner />
          ) : (
            courses.map((course) =>
              course.courseId === parseInt(courseId) ? (
                <CardHeader key={course.courseId} title={course.courseName} />
              ) : (
                ""
              ),
            )
          )}
          <div className="row">
            <div className="col">
              {collectionsLoading ? (
                <CustomSpinner />
              ) : (
                <GroupList
                  array={collections.map((collection) => {
                    return {
                      id: collection.collectionId,
                      label: collection.collectionName,
                      status: collection.status,
                      link: `/question/?collection_id=${collection.collectionId}&course_id=${courseId}`,
                    };
                  })}
                />
              )}
            </div>
          </div>
        </div>
        <div className="mb-4">
          <BackBottom backRoute={"/"} />
        </div>
      </div>
      <Footer />
    </div>
  );
};
