import React from "react";
import DOMPurify from "dompurify";

export const convertNewLine = (text) => {
  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }),
    };
  };

  if (text === null) {
    return <></>;
  }

  if (Array.isArray(text) === false && !text.includes("</")) {
    return <div>{text}</div>;
  }

  // タグが含まれているかどうか
  if (text.includes("</")) {
    return (
      <div
        className="preview"
        dangerouslySetInnerHTML={createMarkup(text)}
      ></div>
    );
  } else {
    const texts = text.split("\n").map((item, index) => {
      // <></> は key を設定できないので、<React.Fragment /> を使う
      return (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      );
    });
    return <div>{texts}</div>;
  }
};
