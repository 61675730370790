import React from "react";

export const SlideFullImage = (props) => {
  const { imageFileUrl } = props;

  return (
    <div className="row">
      <div className="col" sm={12} lg={12}>
        <img className="img-fluid" alt="" src={imageFileUrl} />
      </div>
    </div>
  );
};
