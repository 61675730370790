import { useCallback, useState } from "react";
import axiosInstance from "../configs/axios";
import { awsExports } from "../configs/aws-exports";

export const useUsers = () => {
  const [usersLoading, setUsersLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getUsers = useCallback((userId) => {
    setUsersLoading(true);
    const url = userId ? "/users?user_id=" + userId : "/users";
    axiosInstance
      .get(url, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => setUsers(res.data))
      .catch((err) => console.log("初期ロードに失敗: " + err))
      .finally(() => setUsersLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    getUsers,
    users,
    usersLoading,
  };
};
