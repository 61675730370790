import React from "react";
import { Link } from "react-router-dom";

export const SubmitButton = (props) => {
  const { route, state, label } = props;

  return (
    <Link
      to={route}
      state={state}
      className={"submit-button"}
      onClick={props.onClick}
      data-test="submit-button"
    >
      <div>{label}</div>
    </Link>
  );
};
