import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useCookies } from "react-cookie";
import { CustomSpinner } from "../components/CustomSpinner";

export const ProtectedRouter = () => {
  const [authState, setAuthState] = useState("loading");
  const location = useLocation();
  const navigate = useNavigate();
  const [, setCookie] = useCookies(["originalUrl"]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setAuthState("authenticated");
      } catch {
        setAuthState("unauthenticated");
        const fullPath = `${location.pathname}${location.search}`;
        setCookie("originalUrl", fullPath, { path: "/", maxAge: 3600 }); // 1時間後に有効期限切れ
        navigate("/signin", { replace: true });
      }
    };

    checkAuth();
  }, [location, navigate, setCookie]);

  if (authState === "loading") {
    return (
      <div className="bg-light min-vh-100">
        <CustomSpinner />
      </div>
    );
  }

  return authState === "authenticated" ? <Outlet /> : null;
};
