import { useCallback, useState } from "react";
import axiosInstance from "../../configs/axios";
import { awsExports } from "../../configs/aws-exports";

export const useUserWeeklyStats = () => {
  const [userWeeklyStatsLoading, setUserWeeklyStatsLoading] = useState(true);
  const [userWeeklyStats, setUserWeeklyStats] = useState([]);

  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getUserWeeklyStats = useCallback((userId) => {
    setUserWeeklyStatsLoading(true);
    let url = "/user-weekly-stats?user_id=" + userId;
    axiosInstance
      .get(url, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => setUserWeeklyStats(res.data))
      .catch((err) => console.log("初期ロードに失敗: " + err))
      .finally(() => setUserWeeklyStatsLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    getUserWeeklyStats,
    userWeeklyStats,
    userWeeklyStatsLoading,
  };
};
