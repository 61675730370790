import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../components/base/Header";
import { CustomSpinner } from "../../components/CustomSpinner";
import { useUserSetting } from "../../hooks/setting/useUserSetting";
import { awsExports } from "../../configs/aws-exports";
import axiosInstance from "../../configs/axios";
import ReactGA from "react-ga4";
import {useUsers} from "../../hooks/useUsers";

export const UserSettingPage = () => {
  // projectIdをクエリパラメータから取得
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get("user_id");

  let navigate = useNavigate();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const { getUserSetting, userSetting, userSettingLoading } = useUserSetting();

  useEffect(() => getUserSetting(userId), [getUserSetting, userId]);

  const [username, setUsername] = useState(userSetting?.username || "");
  const onClickUpdateUserSetting = async () => {
    const accessToken = localStorage.getItem(
      "CognitoIdentityServiceProvider." +
        awsExports.Auth.userPoolWebClientId +
        "." +
        localStorage.getItem(
          "CognitoIdentityServiceProvider." +
            awsExports.Auth.userPoolWebClientId +
            ".LastAuthUser",
        ) +
        ".accessToken",
    );

    // プロジェクトを登録する
    await axiosInstance
      .post(
        "/user-setting",
        {
          userId: userId,
          username: username === "" ? userSetting?.username : username,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        },
      )
      .then((res) => {
        console.log(res.data);
        alert("ユーザーの更新が完了しました。");
        navigate("/");
      })
      .catch((err) => alert("Error: " + err));
  };

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="container">
        <div className="card p-3 mt-3 mb-3">
          <div className="mb-3">
            <h2>ユーザー設定</h2>
          </div>
          <div className="row mb-3">
            <div className="col col-lg-12">
              <label htmlFor="username" className="form-label">
                表示名
              </label>
              {userSettingLoading ? (
                <CustomSpinner />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  defaultValue={userSetting?.username || ""}
                  onChange={(e) => setUsername(e.target.value)}
                />
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col col-lg-12">
              {userSettingLoading ? (
                <CustomSpinner />
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={onClickUpdateUserSetting}
                >
                  更新
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="py-4">
          <Link to={"/"}>トップページに戻る</Link>
        </div>
      </div>
    </div>
  );
};
