import React from "react";

export const AnswerCard = (props) => {
  const { headerLabel, children } = props;

  return (
    <div className="mb-3 px-3">
      <p
        style={{
          font: "normal normal bold 16px/19px Yu Gothic",
          letterSpacing: "0px",
          color: "#363636",
        }}
      >
        {headerLabel}
      </p>
      <div>{children}</div>
    </div>
  );
};
