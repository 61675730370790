import { atom } from "recoil";

export const questionProcessingCollectionId = atom({
  key: "collectionId",
  default: null,
});

export const questionProcessingCourseId = atom({
  key: "courseId",
  default: null,
});

export const questionProcessingCollectionWithQuestionArray = atom({
  key: "collectionWithQuestionIdArray",
  default: {
    collectionId: null,
    collectionName: "",
    description: "",
    questions: [],
  },
});

export const questionProcessingCurrentQuestionNumber = atom({
  key: "currentQuestionNumber",
  default: 0,
});
export const questionProcessingAnswerArray = atom({
  key: "answerArray",
  default: [],
});

export const questionProcessingCollectionHistoryId = atom({
  key: "collectionHistoryId",
  default: null,
});
