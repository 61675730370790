import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="d-flex align-items-center">
          <ul className="list-inline d-flex text-center text-white justify-content-lg-start">
            <li className="list-inline-item me-4">
              <Link to="/" className="text-white text-decoration-none">
                {"プライバシーポリシー"}
              </Link>
            </li>
            <li className="list-inline-item me-4">
              <Link to="/" className="text-white text-decoration-none">
                {"サイトポリシー"}
              </Link>
            </li>
            <li className="list-inline-item me-4">
              <Link to="/" className="text-white text-decoration-none">
                {"問い合わせ"}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
