import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import axiosInstance from "../configs/axios";
import { useCommon } from "../hooks/useCommon";
import { awsExports } from "../configs/aws-exports";

export const BasicLayout = () => {
  const { userId } = useCommon();

  useEffect(() => {
    const accessToken = localStorage.getItem(
      "CognitoIdentityServiceProvider." +
        awsExports.Auth.userPoolWebClientId +
        "." +
        localStorage.getItem(
          "CognitoIdentityServiceProvider." +
            awsExports.Auth.userPoolWebClientId +
            ".LastAuthUser",
        ) +
        ".accessToken",
    );
    axiosInstance
      .post(
        "/last-login-at",
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: accessToken,
          },
        },
      )
      .then((res) => console.log(res))
      .catch((err) => console.log("最終ログインの更新に失敗しました: " + err));
  }, [userId]);

  return (
    <>
      <Outlet />
    </>
  );
};
