import axios from "axios";
import { awsExports } from "./aws-exports";
import { apiUrl } from "./common";

export const authUser = localStorage.getItem(
  "CognitoIdentityServiceProvider." +
    awsExports.Auth.userPoolWebClientId +
    ".LastAuthUser",
);
export const accessToken = localStorage.getItem(
  "CognitoIdentityServiceProvider." +
    awsExports.Auth.userPoolWebClientId +
    "." +
    localStorage.getItem(
      "CognitoIdentityServiceProvider." +
        awsExports.Auth.userPoolWebClientId +
        ".LastAuthUser",
    ) +
    ".accessToken",
);
// export const cognitoAttributes = JSON.parse(localStorage.getItem('CognitoIdentityServiceProvider.' + awsExports.Auth.userPoolWebClientId + '.' + localStorage.getItem('CognitoIdentityServiceProvider.' + awsExports.Auth.userPoolWebClientId + '.LastAuthUser') + '.userData')).UserAttributes;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
  },
});

export default axiosInstance;
