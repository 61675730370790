import { useCallback, useState } from "react";
import axiosInstance from "../configs/axios";
import { awsExports } from "../configs/aws-exports";

export const useCollections = () => {
  const [collectionsLoading, setCollectionsLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getCollections = useCallback((userId, courseId) => {
    setCollectionsLoading(true);

    const url = "/collections?user_id=" + userId + "&course_id=" + courseId;
    axiosInstance
      .get(url, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => setCollections(res.data))
      .catch((err) => console.log("初期ロードに失敗: " + err))
      .finally(() => setCollectionsLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    getCollections,
    collections,
    collectionsLoading,
  };
};
