import React from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDropleftCircle } from "react-icons/io";

export const BackBottom = (props) => {
  const { backRoute } = props;

  return (
    <Link to={backRoute} style={{ textDecoration: "none" }}>
      <p className="d-flex align-items-center">
        <IoIosArrowDropleftCircle
          className="me-1"
          style={{
            width: "30px",
            height: "30px",
          }}
        />
        <span
          style={{
            font: "normal normal bold 12px/14px Yu Gothic",
            color: "#333333",
          }}
        >
          {"戻る"}
        </span>
      </p>
    </Link>
  );
};
