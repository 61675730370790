export const GetStatusLabelAndStyles = (status) => {
  let statusLabel;
  let backgroundColor;
  let textColor;
  let hoverBackgroundColor;
  let hoverTextColor;
  let labelBackgroundColor;
  let labelTextColor;
  let hoverLabelBackgroundColor;
  let hoverLabelTextColor;

  switch (status) {
    case 0:
      statusLabel = "視聴開始待ち";
      backgroundColor = "#E8E8E8";
      textColor = "#333333";
      hoverBackgroundColor = "#8B8B8B";
      hoverTextColor = "#FFFFFF";
      labelBackgroundColor = "#8B8B8B";
      labelTextColor = "#FFFFFF";
      hoverLabelBackgroundColor = "#FFFFFF";
      hoverLabelTextColor = "#8B8B8B";
      break;
    case 1:
      statusLabel = "視聴完了";
      backgroundColor = "#EEF9E8";
      textColor = "#333333";
      hoverBackgroundColor = "#83BB2E";
      hoverTextColor = "#FFFFFF";
      labelBackgroundColor = "#83BB2E";
      labelTextColor = "#FFFFFF";
      hoverLabelBackgroundColor = "#FFFFFF";
      hoverLabelTextColor = "#83BB2E";
      break;
    case 2:
      statusLabel = "未視聴";
      backgroundColor = "#F9F6EB";
      textColor = "#333333";
      hoverBackgroundColor = "#CB933F";
      hoverTextColor = "#FFFFFF";
      labelBackgroundColor = "#CB933F";
      labelTextColor = "#FFFFFF";
      hoverLabelBackgroundColor = "#FFFFFF";
      hoverLabelTextColor = "#CB933F";
      break;
    case 3:
      statusLabel = "学習中";
      backgroundColor = "#EEF6F6";
      textColor = "#333333";
      hoverBackgroundColor = "#2342B5";
      hoverTextColor = "#FFFFFF";
      labelBackgroundColor = "#2342B5";
      labelTextColor = "#FFFFFF";
      hoverLabelBackgroundColor = "#FFFFFF";
      hoverLabelTextColor = "#2342B5";
      break;
    default:
      statusLabel = "未視聴";
      backgroundColor = "#F9F6EB";
      textColor = "#333333";
      hoverBackgroundColor = "#CB933F";
      hoverTextColor = "#FFFFFF";
      labelBackgroundColor = "#CB933F";
      labelTextColor = "#FFFFFF";
      hoverLabelBackgroundColor = "#FFFFFF";
      hoverLabelTextColor = "#CB933F";
  }

  return {
    statusLabel,
    backgroundColor,
    textColor,
    hoverBackgroundColor,
    hoverTextColor,
    labelBackgroundColor,
    labelTextColor,
    hoverLabelBackgroundColor,
    hoverLabelTextColor,
  };
};
