import React, { useEffect, useState } from "react";
import { PostLog } from "../../PostLog";
import { DateTime } from "luxon";
import { s3Url } from "../../../configs/common";
import { compareAnswer } from "../../../functions/compareAnswer";
import { ProgressBar } from "../../parts/progresses/ProgressBar";
import { CollectionTitle } from "../../parts/questions/CollectionTitle";
import { QuestionDescription } from "../../parts/questions/QuestionDescription";
import { SubmitButton } from "../../parts/buttons/SubmitButton";
import { QuestionSentence } from "../../parts/questions/QuestionSentence";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  questionProcessingAnswerArray,
  questionProcessingCollectionHistoryId,
  questionProcessingCollectionWithQuestionArray,
  questionProcessingCurrentQuestionNumber,
} from "../../../stores/QuestionProcessingAtoms";

export const isEqualArray = function (array1, array2) {
  let i = array1.length;
  if (i !== array2.length) return false;

  while (i--) {
    if (array1[i] !== array2[i]) return false;
  }
  return true;
};

export const DictationQuestion = (props) => {
  const { questionsNumber, userId } = props;

  const collection = useRecoilValue(
    questionProcessingCollectionWithQuestionArray,
  );
  const collectionId = collection.collectionId;
  const collectionName = collection.collectionName;
  const description = collection.description;
  const questionArray = collection.questions;
  const currentQuestionNumber = useRecoilValue(
    questionProcessingCurrentQuestionNumber,
  );
  const [answerArray, setAnswerArray] = useRecoilState(
    questionProcessingAnswerArray,
  );
  let previousAnswerArray = [...answerArray];
  const collectionHistoryId = useRecoilValue(
    questionProcessingCollectionHistoryId,
  );

  const startAt = DateTime.now();
  const [correctAnswerStatus, setCorrectAnswerStatus] = useState(0);

  const [currentChoiceNumber, setCurrentChoiceNumber] = useState(0);
  const [selectedAnswerArray, setSelectedAnswerArray] = useState([
    ...questionArray[currentQuestionNumber].answer.map((item, index) =>
      index.toString(),
    ),
  ]);
  const [choiceFlgArray, setChoiceFlgArray] = useState([
    ...questionArray[currentQuestionNumber].choices.map((item) => ({
      choice: item,
      flg: false,
    })),
  ]);

  const onClickItem = (e) => {
    if (currentChoiceNumber < selectedAnswerArray.length) {
      setSelectedAnswerArray(
        selectedAnswerArray.map((answer, index) =>
          index === currentChoiceNumber ? e.target.value : answer,
        ),
      );
      setChoiceFlgArray(
        questionArray[currentQuestionNumber].choices.map((choice, index) => {
          return (choice === e.target.value &&
            index === Number.parseInt(e.target.name)) ||
            choiceFlgArray[index].flg === true
            ? {
                choice: choice,
                flg: true,
              }
            : { choice: choice, flg: false };
        }),
      );
      setCurrentChoiceNumber(currentChoiceNumber + 1);
    }
    setCorrectAnswerStatus(
      compareAnswer(
        questionArray[currentQuestionNumber].answer,
        answerArray[currentQuestionNumber],
      )
        ? 1
        : 0,
    );
  };

  const onClickChoiceReset = () => {
    setSelectedAnswerArray([
      ...questionArray[currentQuestionNumber].answer.map((item, index) =>
        index.toString(),
      ),
    ]);
    setCurrentChoiceNumber(0);
    setChoiceFlgArray([
      ...questionArray[currentQuestionNumber].choices.map(() => false),
    ]);
    setCorrectAnswerStatus(0);
  };

  const onClickChoiceBack = () => {
    if (currentChoiceNumber > 0) {
      setSelectedAnswerArray(
        selectedAnswerArray.map((selectedAnswer, index) =>
          index === currentChoiceNumber - 1 ? index.toString() : selectedAnswer,
        ),
      );
      setChoiceFlgArray(
        choiceFlgArray.map((item) => {
          return {
            choice: item.choice,
            flg:
              item.choice === selectedAnswerArray[currentChoiceNumber - 1] &&
              item.flg === true
                ? false
                : item.flg,
          };
        }),
      );
      setCurrentChoiceNumber(currentChoiceNumber - 1);
    }
    setCorrectAnswerStatus(0);
  };

  let shuffleChoices = [...questionArray[currentQuestionNumber].choices];
  if (currentChoiceNumber === 0) {
    for (let i = shuffleChoices.length - 1; i > 0; i--) {
      let r = Math.floor(Math.random() * (i + 1));
      let tmp = shuffleChoices[i];
      shuffleChoices[i] = shuffleChoices[r];
      shuffleChoices[r] = tmp;
    }
  }

  useEffect(() => {
    if (previousAnswerArray.length !== currentQuestionNumber) {
      previousAnswerArray.pop();
    }
    setAnswerArray([...previousAnswerArray, selectedAnswerArray]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswerArray]);

  return (
    <>
      <ProgressBar
        count={questionsNumber.questionNumber}
        totalCount={questionsNumber.questionsLength}
      />
      <CollectionTitle>{collectionName}</CollectionTitle>
      <QuestionDescription>{description}</QuestionDescription>
      {questionArray[currentQuestionNumber].questionImagePath === null ? (
        <></>
      ) : (
        <div>
          <img
            src={
              s3Url +
              "/" +
              questionArray[currentQuestionNumber].questionImagePath
            }
            alt={"問題の画像"}
          />
        </div>
      )}
      <QuestionSentence>
        {questionArray[currentQuestionNumber].sentence}
      </QuestionSentence>
      <div className="px-3 pb-3">
        <audio
          src={
            s3Url + "/" + questionArray[currentQuestionNumber].questionAudioPath
          }
          controls
          className="w-100"
        />
      </div>
      <div className="mt-2 px-3 pb-3">
        <div className="my-3 d-flex flex-wrap" data-test="shuffle-choices">
          {shuffleChoices.map((item, index) => (
            <input
              key={index}
              name={index.toString()}
              className={
                "me-2 mb-2 btn btn-info text-light " +
                (choiceFlgArray[index].flg ? "visually-hidden " : " ")
              }
              onClick={(e) => onClickItem(e)}
              value={item}
              type="submit"
            />
          ))}
        </div>
        <div className="my-3">
          <div
            onClick={(e) => onClickChoiceBack(e)}
            className="btn btn-light me-2"
          >
            {"一つ戻る"}
          </div>
          <div
            className="btn btn-light me-2"
            onClick={(e) => onClickChoiceReset(e)}
          >
            {"回答をリセット"}
          </div>
        </div>
        <div className="my-3">
          <div className="d-flex flex-wrap" data-test="answer-array">
            {selectedAnswerArray.map((item, index) => {
              return (
                <span key={index} className="me-2 mb-2 btn btn-light">
                  {parseInt(item) === index ? index + 1 : item}
                </span>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-center pb-3">
          <SubmitButton
            route={"/answer"}
            onClick={() =>
              PostLog({
                logType: "question",
                userId: userId,
                collectionId: collectionId,
                answer: answerArray[currentQuestionNumber],
                questionId: questionArray[currentQuestionNumber]["_id"],
                collectionHistoryId: collectionHistoryId,
                startAt: startAt.toISO(),
                correctAnswerStatus: correctAnswerStatus,
                correctAnswer: questionArray[currentQuestionNumber].answer,
              })
            }
            label={"回答する"}
          />
        </div>
      </div>
    </>
  );
};
