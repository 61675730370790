import React from "react";

export const CollectionTitle = (props) => {
  return (
    <div className="px-4 py-5">
      <h2
        style={{
          font: "normal normal bold 20px/24px Yu Gothic",
          letterSpacing: "0px",
          color: "#363636",
        }}
      >
        {props.children}
      </h2>
    </div>
  );
};
