import React from "react";
import { Accordion as BootstrapAccordion } from "react-bootstrap";

export const AnswerAccordion = (props) => {
  const { header, body, activeKey } = props;

  return (
    <BootstrapAccordion defaultActiveKey={activeKey ? "0" : ""}>
      <BootstrapAccordion.Item eventKey="0">
        <BootstrapAccordion.Header>{header}</BootstrapAccordion.Header>
        <BootstrapAccordion.Body>{body}</BootstrapAccordion.Body>
      </BootstrapAccordion.Item>
    </BootstrapAccordion>
  );
};
