import React from "react";

export const CardHeader = (props) => {
  const { title } = props;

  return (
    <div className="row mt-5 mb-5">
      <div className="col">
        <h2
          className="ps-3 mb-0"
          style={{
            font: "normal normal bold 20px/24px Yu Gothic",
            borderLeft: "5px solid #2342B5",
          }}
        >
          {title}
        </h2>
      </div>
    </div>
  );
};
