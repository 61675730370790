import React from "react";

export const PrivacyPolicyContent = (props) => {
  return (
    <>
      <h1>プライバシーポリシー</h1>
      <p>
        株式会社
        8.1.1は、お客様のマーケティング支援をはじめとするコンサルティング事業及びその他の事業目的に応じて、個人情報を含む様々な情報を取得し、利用しております。これらの個人情報を安全に正しく取り扱うことは、事業活動における重要な責務であると深く認識し、適切な個人情報保護の実施を目的とする基本方針を下記のとおり宣言します。
      </p>

      <h2>個人情報保護方針</h2>
      <p>
        株式会社
        8.1.1は、お客様のマーケティング支援をはじめとするコンサルティング事業及びその他の事業目的に応じて、個人情報を含む様々な情報を取得し、利用しております。これらの個人情報を安全に正しく取り扱うことは、事業活動における重要な責務であると深く認識し、適切な個人情報保護の実施を目的とする基本方針を下記のとおり宣言します。
      </p>

      <h2>個人情報の取得、利用及び提供</h2>
      <h3>取得</h3>
      <p>
        個人情報の取得にあたっては、利用目的と利用範囲をできる限り特定し、当該目的の達成に必要な範囲内で適法かつ公正な手段を用いるとともに、利用目的と利用範囲について、あらかじめ同意を得た上で行います。
      </p>

      <h3>利用</h3>
      <p>取得した個人情報は、同意を得た利用目的に限定して利用します。</p>

      <h3>提供</h3>
      <p>
        取得した個人情報の提供にあたっては、同意を得た提供範囲に限定します。同意のない第三者への提供は一切行いません。また、当社が、個人情報の処理を外部に委託する場合には、漏えいや再提供を行わないよう契約により義務付け、適切な管理を行います。
      </p>

      <h2>法令・指針・規範の順守</h2>
      <p>
        当社が保有する個人情報に関して適用される法令、国が定める指針及びその他の関係する規範を遵守します。
      </p>

      <h2>個人情報の適正管理</h2>
      <p>
        個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等の防止並びに是正に関する適切な措置を講じます。
      </p>

      <h2>苦情・相談への対応</h2>
      <p>
        個人情報の確認、訂正、追加または削除等をご希望される場合には、当社各窓口までご連絡いただければ、合理的な範囲で速やかに対応します。また、個人情報の保護と適正な取扱いに関する苦情・相談に適切かつ迅速に対応します。
      </p>

      <h2>継続的改善</h2>
      <p>
        マネジメントレビュー及び苦情・相談の機会を通じて、個人情報保護マネジメントシステムを継続的に改善し、最良の状態を維持します。
      </p>

      <h2>個人情報の利用目的</h2>
      <p>
        当社は、ご本人より書面やWebサイトを通じて直接個人情報を取得する場合は、取得の状況から利用目的が明らかである場合及び法令により利用目的の明示が不要とされる場合を除き、利用目的を明示します。尚、当社事業における個人情報の主な利用目的には、次のようなものがあります。
      </p>
      <ul>
        <li>調査実施記録の管理及び調査内容の分析</li>
        <li>調査対象者に対するアンケート</li>
        <li>インタビューの実施及びお問お合わせへの対応</li>
        <li>当社の商品・サービス・サポート資料、カタログ、刊行物の発送</li>
        <li>当社及び当社提携事業者が実施するイベント等に関する案内</li>
        <li>お客様への商品・サービス・サポートの提案、販売、提供</li>
        <li>契約の履行、取引にかかわる各種連絡</li>
        <li>お客様からのお問い合わせやご要望への対応</li>
        <li>キャンペーンの実施案内及びプレゼントの送付</li>
        <li>アンケートや商品・サービスの評価モニターの協力依頼</li>
        <li>メールマガジンのメンバー登録の案内及びメールマガジンの送信</li>
        <li>お客様に有益と思われる情報のお届け</li>
        <li>その他、お客様に事前にお知らせし、ご同意いただいた目的での利用</li>
      </ul>

      <p>
        書面やWebサイト経由以外の方法で直接ご本人から個人情報を取得する場合（電話等）や、公開情報や第三者を経由して取得する場合の利用目的は、取得の状況から利用目的が明らかである場合及び法令により利用目的の通知・公表が不要とされる場合を除き、次の通りとします。
      </p>

      <h2>個人情報の第三者への提供</h2>
      <p>
        取得した個人情報の提供にあたっては、同意を得た提供範囲に限定します。以下の場合を除き、同意のない第三者への提供は一切行いません。
      </p>
      <ul>
        <li>法令に基づく場合</li>
        <li>
          人の生命、身体、財産の保護のために必要であるが、ご本人の同意を得ることが難しい場合
        </li>
        <li>
          公衆衛生の向上または児童の健全な育成のために必要であるが、ご本人の同意を得ることが難しい場合
        </li>
        <li>
          国もしくは地方自治体等の公共機関やその委託を受けた者が法令の定める事務を遂行するために協力が必要であるが、ご本人の同意を得ることで当該事務の遂行に支障を及ぼす恐れがある場合
        </li>
        <li>
          法人その他の団体の役員及び株主等に関する公開情報で、あらかじめ第三者提供等について、ご本人に通知または公表している場合
        </li>
        <li>
          特定した利用目的の達成に必要な範囲内において、個人情報の取扱いの全部または一部を委託する場合
        </li>
        <li>
          合併その他の事由による事業承継に伴い個人情報を提供する場合で、承継前の利用目的の範囲内で当該個人情報を取扱う場合
        </li>
        <li>法令等に定める手順を経て、個人情報を共同利用する場合</li>
      </ul>

      <h2>個人情報の取扱いの委託</h2>
      <p>
        当社では、業務を円滑に遂行するため、個人情報の処理を外部に委託する場合がありますが、この場合は、漏えいや再提供を行わないよう契約により義務付け、適切な管理を行います。
      </p>

      <h2>個人情報に関する苦情・相談</h2>
      <p>
        個人情報について、「利用目的の通知、開示、訂正、追加、削除、利用停止、消去、第三者への提供の停止」（以下。「開示等の求め」といいます）をご希望される方は、以下のお問い合わせ窓口に郵送にてご連絡ください。
      </p>
      <p>
        その後、郵送または電子メールにて当社より所定の請求書を送付・送信いたしますので、所定の事項を全てご記入の上、以下の「本人確認のための書類」を添付し、以下の住所宛にご郵送ください。その際、封筒に朱書きで「開示等請求書類在中」とお書き添えください。尚、電話、FAX、電子メール、面会等の方法によるご請求は受け付けておりません。
      </p>

      <h3>郵送によるお問い合わせ窓口</h3>
      <p>
        〒113-0033 東京都文京区本郷3-21-8 ケイアイビル6階
        <br />
        株式会社 8.1.1 個人情報お問い合わせ窓口
      </p>

      <h3>本人確認のための書類（以下のいずれか一つ）</h3>
      <ul>
        <li>「運転免許証」のコピー（本籍欄を黒く塗りつぶしてください。）</li>
        <li>「パスポート」のコピー</li>
        <li>「住民票の写し」（コピー不可）</li>
      </ul>

      <h3>代理人の方がご請求される場合</h3>
      <p>
        本人が未成年者または成年被後見人である場合の法定代理人もしくは「開示等の求め」をすることにつき本人が委任した代理人による請求の場合は、上記の書類に加えて、以下の書類を同封してください。
      </p>
      <ul>
        <li>
          法定代理人の場合（以下の書類全て）
          <ul>
            <li>当社所定の請求書</li>
            <li>
              法定代理権があることを確認するための書類（戸籍謄本、親権者の場合は扶養家族が記入された保険証のコピーも可）
            </li>
            <li>法定代理人の「本人確認のための書類」（上記に準ずる）</li>
          </ul>
        </li>
        <li>
          委任による代理人の場合
          <ul>
            <li>委任状（ご本人が自筆で記入し実印が押印されているもの）</li>
            <li>ご本人の印鑑証明書</li>
            <li>代理人の「本人確認のための書類」（上記に準ずる）</li>
          </ul>
        </li>
      </ul>

      <h3>手数料及びその徴収方法</h3>
      <p>
        利用目的の通知または開示のご請求については、1回の請求毎に手数料として1,000円を申し受けます（当社からの返信のための通信費を含みます）ので、同額の郵便切手を同封してください。但し、開示資料が複数枚に渡る場合は、別途実費をいただく場合があります。また、手数料の額は、予告なく変更させていただくことがございますので、あらかじめご了承ください。
        尚、手数料が不足していた場合、または同封されていなかった場合は、当社よりご連絡しますが、所定の期間内にお支払いがない場合は、開示等のご請求がなかったものとして対応させていただきます。
      </p>

      <h3>当社からの回答方法</h3>
      <p>
        請求された方の請求書記載住所宛に書面にてご回答申し上げます。尚、回答書は、請求の受付から1か月以内を目処に発送いたしますが、事情により遅れることがございますので、あらかじめご了承ください。
      </p>

      <h3>「開示等の求め」に際して、当社が取得した個人情報の管理</h3>
      <p>
        「開示等の求め」に伴い取得した個人情報は、「開示等の求め」に必要な範囲内において取り扱います。提出いただいた書類は、その回答が終了した後、2年間保存し、その後廃棄します。
      </p>

      <p>
        上記における「開示等の求め」に対して、以下に定める場合は、不開示とさせていただきます。不開示を決定した場合には、その理由を付記してご回答します。尚、不開示の場合でも、上記の手数料は返還しませんので、あらかじめご了承ください。
      </p>
      <ul>
        <li>ご本人または代理人の本人確認ができない場合</li>
        <li>代理人による請求に際して、代理権が確認できない場合</li>
        <li>所定の請求書類に不備があった場合</li>
        <li>「開示等の求め」の対象が当社が保有する個人情報に該当しない場合</li>
        <li>
          ご本人または第三者の生命、身体、財産その他の権利または利益を害する恐れがある場合
        </li>
        <li>当社の業務の適正な実施に著しい支障を及ぼす恐れがある場合</li>
        <li>法令に違反することになる場合</li>
      </ul>

      <h2>当社保有個人情報に関する「利用目的の通知」のご請求について</h2>
      <p>
        当社が公表している利用目的（上記「個人情報の利用目的」）により、ご本人様が識別される当社保有個人情報の利用目的が明らかな場合、当社保有個人情報に関する「内容の訂正、追加または削除」のご請求について、当該請求が、1）ご本人様が識別される当社保有個人情報の内容が事実でないという理由によってなされたものでない場合、または2）「内容の訂正、追加または削除」に関して他の法令の規定により特別の手続が定められている場合。
      </p>

      <h2>当社保有個人情報に関する「利用の停止または消去」のご請求について</h2>
      <p>
        当該請求が、1）ご本人様が識別される当社保有個人情報が法令の規定に違反して取り扱われているという理由もしくは法令の規定に違反して取得されたものであるという理由によってなされたものでない場合、2）請求に理由があることが判明しない場合、または3）当社保有個人情報の「利用の停止または消去」に多額の費用を要する場合その他の利用停止を行うことが困難な場合であって、ご本人様の権利または利益を保護するため必要なこれに代わるべき措置をとる場合。
      </p>

      <h2>当社保有個人情報に関する「第三者への提供の停止」の請求について</h2>
      <p>
        当該請求が、1）ご本人様が識別される保有個人情報が法令の規定に違反して第三者に提供されているという理由によってなされたものでない場合、2）請求に理由があることが判明しない場合、または3）保有個人データの「第三者への提供の停止」に多額の費用を要する場合その他の第三者への提供の停止を行うことが困難な場合であって、ご本人様の権利または利益を保護するため必要なこれに代わるべき措置をとる場合。
      </p>

      <h2>個人情報の取扱いに関するお問い合わせ及び苦情等</h2>
      <p>
        個人情報を直接ご提供された各サービスの問い合わせ窓口までご連絡ください。問い合わせ窓口が不明な場合、以下のいずれかの方法でご連絡ください。
      </p>
      <ul>
        <li>
          以下宛先への郵送
          <br />
          〒113-0033 東京都文京区本郷3-21-8 ケイアイビル6階
          <br />
          株式会社 8.1.1 個人情報保護管理者
        </li>
        <li>当社運営Webサイトにおけるお問い合わせフォームへのご記入</li>
      </ul>

      <h2>当社運営Webサイトでのウェブ技術上の対応</h2>
      <p>
        SSLの採用当社のWebサイトでは、お客様の個人情報を暗号化して安全に通信するためにSSL(Secure
        Socket Layer)を採用しています。
      </p>

      <h2>クッキー（Cookie）の利用</h2>
      <p>
        当社のWebサイトでは、お客様のコンピュータを効率的に特定するために、クッキー（Cookie）技術を採用しています。クッキーには、氏名、住所、電話番号、生年月日等の個人情報は含まれておりません。
      </p>

      <h2>アクセスログの記録</h2>
      <p>
        当社のWebサイトでは、Webサイトの情報セキュリティを確保するために、お客様がログイン・ログアウトされたことをアクセスログに記録しています。アクセスログには、氏名、住所、電話番号、生年月日等の個人情報は含まれておりません。
      </p>

      <h2>本ページについて</h2>
      <p>
        当社のWebサイトからリンクされている他社のWebサイトにおける個人情報の取扱い方針や内容については、当社は責任を負いません。
      </p>
      <p>
        当社では、利用目的の変更、安全性向上、また関連法令及び規範の改定等に応じて、本「個人情報保護方針」並びに本ページ記載事項を改定する場合があります。お客様におかれましては、当社Webサイト等にて定期的にご確認ください。
      </p>
      <p>
        株式会社 8.1.1
        <br />
        代表取締役 今木智隆
      </p>
      <p>制定：2022年12月16日</p>
    </>
  );
};
