import React from "react";
import { Dropdown } from "react-bootstrap";
import { SignOut } from "../SignOut";
import { stripeCustomerPortalUrl } from "../../configs/common";

export const DropdownMenu = (props) => {
  const { userId, projectId, adminFlg } = props;

  return (
    <Dropdown>
      <Dropdown.Toggle className="dropdown-toggle" id="dropdown-basic">
        <span className="navbar-toggler-icon"></span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {adminFlg ? (
          <>
            <Dropdown.Item href={"/stats/project?project_id=" + projectId}>
              全体学習履歴
            </Dropdown.Item>
            <Dropdown.Item href={"/stats/user?user_id=" + userId}>
              学習履歴
            </Dropdown.Item>
          </>
        ) : (
          <Dropdown.Item href={"/stats/user?user_id=" + userId}>
            学習履歴
          </Dropdown.Item>
        )}
        {adminFlg ? (
          <Dropdown.Item href={"/usage?project_id=" + projectId}>
            利用状況
          </Dropdown.Item>
        ) : (
          <></>
        )}
        {adminFlg ? (
          <Dropdown.Item href={stripeCustomerPortalUrl}>
            契約・請求書
          </Dropdown.Item>
        ) : (
          <></>
        )}
        <Dropdown.Item href={"/user-setting?user_id=" + userId}>
          設定
        </Dropdown.Item>
        <Dropdown.Item onClick={SignOut}>サインアウト</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
