import React, { useEffect, useMemo, useRef } from "react";
import Hls from "hls.js";

export const SlideFullVideo = (props) => {
  const { videoFileUrl } = props;

  const isSupportBrowser = useMemo(() => Hls.isSupported(), []);
  const videoRef = useRef(null);

  useEffect(() => {
    console.log("isSupportBrowser: " + isSupportBrowser);
    if (isSupportBrowser) {
      let hls = new Hls();
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(videoFileUrl);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            "manifest loaded, found " + data.levels.length + " quality level",
          );
          hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // try to recover network error
                  console.log(
                    "fatal network error encountered, try to recover",
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("fatal media error encountered, try to recover");
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
          });
        });
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="px-4 pt-3 pb-3">
      {isSupportBrowser ? (
        <video controls muted className="w-100" ref={videoRef} />
      ) : (
        <video controls muted className="w-100" src={videoFileUrl} />
      )}
    </div>
  );
};
