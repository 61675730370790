import React from "react";

export const CorrectIncorrectLabel = (props) => {
  const { correctFlg } = props;

  return (
    <div
      style={{
        width: "60px",
        height: "30px",
        background: "#1ECAB9 0% 0% no-repeat padding-box",
        backgroundColor: correctFlg ? "#1ECAB9" : "#FE6851",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span
        style={{
          color: "#FFFFFF",
          font: "normal normal normal 12px/14px Yu Gothic",
        }}
      >
        {correctFlg ? "正解" : "不正解"}
      </span>
    </div>
  );
};
