import React from "react";
import { GetStatusLabelAndStyles } from "../../../functions/GetStatusLabelAndStyles";

export const StatusLabel = (props) => {
  const { status, isHover } = props;

  const labelAndStyles = GetStatusLabelAndStyles(status);

  return (
    <div
      style={{
        float: "right",
        backgroundColor: isHover
          ? labelAndStyles.hoverLabelBackgroundColor
          : labelAndStyles.labelBackgroundColor,
        color: isHover
          ? labelAndStyles.hoverLabelTextColor
          : labelAndStyles.labelTextColor,
        textAlign: "center",
        padding: "9px 12px",
        borderRadius: "2em",
        font: "normal normal normal 12px/14px Yu Gothic",
        width: "72px",
      }}
    >
      {labelAndStyles.statusLabel}
    </div>
  );
};
