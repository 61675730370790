export const compareAnswer = (currentAnswer, answerArray) => {
  if (answerArray === null || answerArray === undefined) {
    return false;
  }

  if (currentAnswer.length > 1) {
    for (let i = 0; i < currentAnswer.length; i++) {
      if (currentAnswer[i] !== answerArray[i]) {
        return false;
      }
    }
    return true;
  } else {
    return currentAnswer[0] === answerArray[0];
  }
};
