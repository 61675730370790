import React from "react";
import { convertNewLine } from "../../../functions/convertNewLine";

export const SlideStandard = (props) => {
  const { title, subtitle, sentence, audioFileUrl } = props;

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12">
        <h2>{title}</h2>
        <h3>{subtitle}</h3>
      </div>
      <div className="col-sm-12 col-lg-12">
        <div className="card h-100">
          <div className="card-body">
            {audioFileUrl === null || audioFileUrl === undefined ? (
              <></>
            ) : (
              <audio controls src={audioFileUrl} className="w-100 my-6" />
            )}
            {convertNewLine(sentence)}
          </div>
        </div>
      </div>
    </div>
  );
};
