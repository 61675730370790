import React, { useEffect, useMemo, useRef, useState } from "react";
import { PostLog } from "../../PostLog";
import { DateTime } from "luxon";
import { s3Url } from "../../../configs/common";
import { compareAnswer } from "../../../functions/compareAnswer";
import { convertNewLine } from "../../../functions/convertNewLine";
import { ProgressBar } from "../../parts/progresses/ProgressBar";
import { CollectionTitle } from "../../parts/questions/CollectionTitle";
import { QuestionDescription } from "../../parts/questions/QuestionDescription";
import { SubmitButton } from "../../parts/buttons/SubmitButton";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  questionProcessingAnswerArray,
  questionProcessingCollectionHistoryId,
  questionProcessingCollectionWithQuestionArray,
  questionProcessingCurrentQuestionNumber,
} from "../../../stores/QuestionProcessingAtoms";
import Hls from "hls.js";

export const ChoiceQuestion = (props) => {
  const { questionsNumber, userId } = props;

  const collection = useRecoilValue(
    questionProcessingCollectionWithQuestionArray,
  );
  const collectionId = collection.collectionId;
  const collectionName = collection.collectionName;
  const description = collection.description;
  const questionArray = collection.questions;
  const currentQuestionNumber = useRecoilValue(
    questionProcessingCurrentQuestionNumber,
  );
  const [answerArray, setAnswerArray] = useRecoilState(
    questionProcessingAnswerArray,
  );
  // 複数選択パターンの場合のみanswerArrayの中に配列を使用する
  const [currentAnswerArray, setCurrentAnswerArray] = useState([]);
  const [previousAnswerArray, setPreviousAnswerArray] = useState(answerArray);
  const collectionHistoryId = useRecoilValue(
    questionProcessingCollectionHistoryId,
  );
  const startAt = DateTime.now();
  const [correctAnswerStatus, setCorrectAnswerStatus] = useState(0);

  const isSupportBrowser = useMemo(() => Hls.isSupported(), []);
  const videoRef = useRef(null);

  console.log(questionArray[currentQuestionNumber]);
  useEffect(() => {
    console.log(
      s3Url + "/" + questionArray[currentQuestionNumber].questionVideoPath,
    );
    if (
      isSupportBrowser &&
      questionArray[currentQuestionNumber].questionVideoPath &&
      questionArray[currentQuestionNumber].questionVideoPath !== null
    ) {
      let hls = new Hls();
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        hls.loadSource(
          s3Url + "/" + questionArray[currentQuestionNumber].questionVideoPath,
        );
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            "manifest loaded, found " + data.levels.length + " quality level",
          );
          hls.on(Hls.Events.ERROR, function (event, data) {
            if (data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // try to recover network error
                  console.log(
                    "fatal network error encountered, try to recover",
                  );
                  hls.startLoad();
                  break;
                case Hls.ErrorTypes.MEDIA_ERROR:
                  console.log("fatal media error encountered, try to recover");
                  hls.recoverMediaError();
                  break;
                default:
                  // cannot recover
                  hls.destroy();
                  break;
              }
            }
          });
        });
      });
    }
    // eslint-disable-next-line
  }, []);
  const onClickMultiChoice = (e) => {
    let tempCurrentAnswerArray = currentAnswerArray;
    if (e.target.checked) {
      tempCurrentAnswerArray = [
        ...tempCurrentAnswerArray,
        parseInt(e.target.value, 10),
      ];
    } else {
      tempCurrentAnswerArray = currentAnswerArray.filter(
        (item) => item !== parseInt(e.target.value),
      );
    }
    setCurrentAnswerArray(tempCurrentAnswerArray.sort());
    setAnswerArray([...previousAnswerArray, tempCurrentAnswerArray]);
    setCorrectAnswerStatus(
      compareAnswer(
        questionArray[currentQuestionNumber].answer,
        answerArray[currentQuestionNumber],
      )
        ? 1
        : 0,
    );
  };

  const onClickChoice = (e) => {
    if (previousAnswerArray.length === currentQuestionNumber) {
      setAnswerArray([...previousAnswerArray, parseInt(e.target.value)]);
    } else {
      setPreviousAnswerArray(previousAnswerArray.slice(0, -1));
      setAnswerArray([...previousAnswerArray, parseInt(e.target.value)]);
    }
    setCorrectAnswerStatus(
      questionArray[currentQuestionNumber].answer[0] ===
        parseInt(e.target.value)
        ? 1
        : 0,
    );
  };

  return (
    <>
      <ProgressBar
        count={questionsNumber.questionNumber}
        totalCount={questionsNumber.questionsLength}
      />
      <CollectionTitle>{collectionName}</CollectionTitle>
      <QuestionDescription>{description}</QuestionDescription>
      {questionArray[currentQuestionNumber].questionImagePath === null ? (
        <></>
      ) : (
        <div>
          <img
            src={
              s3Url +
              "/" +
              questionArray[currentQuestionNumber].questionImagePath
            }
            alt={"問題の画像"}
            className="w-100"
          />
        </div>
      )}
      <div className="mt-5 px-3">
        <p
          style={{
            font: "normal normal bold 14px/17px Yu Gothic",
            letterSpacing: "0px",
            color: "#363636",
          }}
        >
          {convertNewLine(questionArray[currentQuestionNumber].sentence)}
        </p>
      </div>
      <div className="mt-5 px-3">
        {questionArray[currentQuestionNumber].questionAudioPath === null ? (
          <></>
        ) : (
          <audio
            controls
            src={
              s3Url +
              "/" +
              questionArray[currentQuestionNumber].questionAudioPath
            }
            className="w-100"
          />
        )}
      </div>
      <div className="px-3">
        {console.log(videoRef)}
        {questionArray[currentQuestionNumber].questionVideoPath === null ? (
          <></>
        ) : isSupportBrowser ? (
          <video controls muted className="w-100" ref={videoRef} />
        ) : (
          <video
            controls
            muted
            className="w-100"
            src={
              s3Url +
              "/" +
              questionArray[currentQuestionNumber].questionVideoPath
            }
          />
        )}
      </div>
      <div className="mt-4 px-3 pb-3">
        <div className="list-group">
          {questionArray[currentQuestionNumber].choices.map(
            (item, choiceIndex) => {
              if (questionArray[currentQuestionNumber].answer.length > 1) {
                return (
                  <li
                    key={choiceIndex}
                    data-test="choice-item"
                    className="list-group-item"
                  >
                    <label>
                      <input
                        className="form-check-input me-2"
                        type="checkbox"
                        value={choiceIndex}
                        onClick={(e) => onClickMultiChoice(e)}
                      />
                      {item}
                    </label>
                  </li>
                );
              } else {
                if (item === "") {
                  return <></>;
                } else {
                  return (
                    <div
                      key={choiceIndex}
                      style={{
                        border: "1px solid #E5E5E5",
                        borderRadius: "6px",
                      }}
                      className="mb-2"
                      data-test="choice-item"
                    >
                      <label className="list-group-item">
                        <input
                          className="form-check-input me-2"
                          type="radio"
                          value={choiceIndex}
                          aria-label={item}
                          name={questionArray[currentQuestionNumber]["_id"]}
                          onClick={(e) => onClickChoice(e)}
                        />
                        {item}
                      </label>
                    </div>
                  );
                }
              }
            },
          )}
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-center px-3 pb-3">
        <SubmitButton
          route={"/answer"}
          onClick={() =>
            PostLog({
              logType: "question",
              userId: userId,
              collectionId: collectionId,
              answer:
                answerArray[currentQuestionNumber] !== undefined
                  ? answerArray[currentQuestionNumber]
                  : null,
              questionId: questionArray[currentQuestionNumber]["_id"],
              collectionHistoryId: collectionHistoryId,
              startAt: startAt.toISO(),
              correctAnswerStatus: correctAnswerStatus,
              correctAnswer: questionArray[currentQuestionNumber].answer,
            })
          }
          label={"回答する"}
        />
      </div>
    </>
  );
};
