import { useCallback, useState } from "react";
import axiosInstance from "../../configs/axios";
import { awsExports } from "../../configs/aws-exports";

export const useProjectStats = () => {
  const [projectStatsLoading, setProjectStatsLoading] = useState(true);
  const [projectStats, setProjectStats] = useState([]);

  const accessToken = localStorage.getItem(
    "CognitoIdentityServiceProvider." +
      awsExports.Auth.userPoolWebClientId +
      "." +
      localStorage.getItem(
        "CognitoIdentityServiceProvider." +
          awsExports.Auth.userPoolWebClientId +
          ".LastAuthUser",
      ) +
      ".accessToken",
  );

  const getProjectStats = useCallback((projectId) => {
    setProjectStatsLoading(true);
    let url = "/project-stats?project_id=" + projectId;
    axiosInstance
      .get(url, {
        headers: {
          Authorization: accessToken,
        },
      })
      .then((res) => setProjectStats(res.data))
      .catch((err) => console.log("初期ロードに失敗: " + err))
      .finally(() => setProjectStatsLoading(false));
    // eslint-disable-next-line
  }, []);

  return {
    getProjectStats,
    projectStatsLoading,
    projectStats,
  };
};
