import { DateTime } from "luxon";

export const convertJapanDateFormat = (dateString) => {
  // DateTimeオブジェクトを作成し、UTC時間として解釈
  const utcDate = DateTime.fromISO(dateString, { zone: "utc" });

  // 日本のタイムゾーン（Asia/Tokyo）に変換
  const japanDate = utcDate.setZone("Asia/Tokyo");

  return japanDate.toFormat("yyyy年MM月dd日 HH:mm:ss");
};
