import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StatusLabel } from "../labels/StatusLabel";
import { GetStatusLabelAndStyles } from "../../../functions/GetStatusLabelAndStyles";

export const GroupList = (props) => {
  const { array } = props;

  const [isHoverArray, setIsHoverArray] = useState(array.map(() => false));

  const handleMouseEnter = (index) => {
    const tempIsHoverArray = array.map((item, num) => num === index);
    setIsHoverArray(tempIsHoverArray);
  };

  return (
    <ul className="list-group">
      {array.map((item, index) => {
        const labelAndStyles = GetStatusLabelAndStyles(item.status);
        return (
          <Link
            key={item.id}
            data-test={
              item.link.includes("question")
                ? "question-link"
                : "collection-link"
            }
            to={item.link}
            style={{
              textDecoration: "none",
            }}
            // disabled={clickDisableFlg}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => setIsHoverArray(array.map(() => false))}
          >
            <li
              className="list-group-item d-flex justify-content-between align-items-center border-white border-bottom-1"
              style={{
                background: "#F8F8F8 0% 0% no-repeat padding-box",
                backgroundColor: isHoverArray[index]
                  ? labelAndStyles.hoverBackgroundColor
                  : labelAndStyles.backgroundColor,
                border: "0px",
                borderBottom: "1px solid #707070",
              }}
            >
              <p
                className="ps-2 py-2 mb-0"
                style={{
                  color: isHoverArray[index]
                    ? labelAndStyles.hoverTextColor
                    : labelAndStyles.textColor,
                  font: "normal normal normal 14px/17px Yu Gothic",
                }}
              >
                {item.label}
              </p>
              <StatusLabel status={item.status} isHover={isHoverArray[index]} />
            </li>
          </Link>
        );
      })}
    </ul>
  );
};
