import React, { useEffect } from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { Header } from "../../components/base/Header";
import { useProjectStats } from "../../hooks/stats/useProjectStats";
import { CustomSpinner } from "../../components/CustomSpinner";
import { useProjectUserStats } from "../../hooks/stats/useProjectUserStats";
import { convertJapanDateFormat } from "../../functions/convertJapanDateFormat";
import ReactGA from "react-ga4";
import {useCommon} from "../../hooks/useCommon";
import {useUsers} from "../../hooks/useUsers";

export const ProjectStatsPage = () => {
  // projectIdをクエリパラメータから取得
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const projectId = searchParams.get("project_id");

  let navigate = useNavigate();
  const { userId } = useCommon();

  const { getUsers, users, usersLoading } = useUsers();

  useEffect(() => getUsers(userId), [getUsers, userId]);

  useEffect(() => {
    if (!usersLoading) {
      if (!users[0]["initialSettingFlg"]) {
        navigate("/initial-setting");
      }
    }
  }, [users, usersLoading, navigate]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  const { getProjectStats, projectStats } = useProjectStats();

  const { getProjectUserStats, projectUserStatsLoading, projectUserStats } =
    useProjectUserStats();

  useEffect(() => getProjectStats(projectId), [getProjectStats, projectId]);
  useEffect(
    () => getProjectUserStats(projectId),
    [getProjectUserStats, projectId],
  );

  return (
    <div className="bg-light min-vh-100">
      <Header />
      <div className="container">
        <div className="card p-3 mt-4 mb-2">
          <h2>ユーザー個別進捗</h2>
          <div className="row mb-3">
            <div className="col col-lg-12">
              {projectUserStatsLoading ? (
                <CustomSpinner />
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>ユーザー名</th>
                      <th>動画視聴本数</th>
                      <th>視聴完了率</th>
                      <th>最終ログイン</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectUserStats.map((user, index) => {
                      console.log(user);
                      return (
                        <tr key={index}>
                          <td>{user["username"]}</td>
                          <td>{user["completeCount"]}</td>
                          <td>
                            {(user["completeCount"] /
                              projectStats["totalQuestion"]) *
                              100}
                            %
                          </td>
                          <td>{convertJapanDateFormat(user["lastLoginAt"])}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          {/*<div>*/}
          {/*  <h3>動画ごとの進捗率</h3>*/}
          {/*  <div className="col col-lg-12">*/}
          {/*    <table>*/}

          {/*    </table>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="py-4">
          <Link to={"/"}>トップページに戻る</Link>
        </div>
      </div>
    </div>
  );
};
